<template>
  <div v-if="errormessage">
  {{ errormessage }}
  </div>
  <div v-else-if="displaySalesMismatchWarning">
      <sales-mismatch :notify="true" :companyid="this.companyId" :companyname="this.companyName" ></sales-mismatch>
    </div>
  <div v-else class="page-body">
    <div v-if="loaded" class="container-xl">
      <div class="card card-lg">
        <div class="card-body">
          <div class="row">
            <div class="container">
              <div
                v-for="siteLogoDTO in displaySiteLogoDTOs"
                v-bind:key="siteLogoDTO.rowID"
              >
                <img
                  :src="siteLogoDTO.siteImageName"
                  style="padding: 2px; border: solid 1px #e7eaef"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <p class="h3">Advertiser Information:</p>
              <address v-html="lblAdvertiserInfo"></address>
            </div>
            <div class="col-md-6 text-end mb-4 inv-mobile">
              <h2 style="color: black"><b>Invoice</b></h2>
              <div class="text-end">
                <table
                  cellpadding="2"
                  cellspacing="2"
                  border="0"
                  style="
                    float: right;
                    border-right: #d4d0c8 1px solid;
                    border-top: #d4d0c8 1px solid;
                    border-left: #d4d0c8 1px solid;
                    border-bottom: #d4d0c8 1px solid;
                  "
                >
                  <tr>
                    <td bgcolor="#f1f1f5" align="center" width="33%">Date</td>
                    <td bgcolor="#f1f1f5" align="center" width="33%">
                      Invoice Number
                    </td>
                    <td bgcolor="#f1f1f5" align="center" width="33%">Terms</td>
                  </tr>
                  <tr>
                    <td align="center">{{ lblInvoiceDate }}</td>
                    <td align="center" class="font-weight-bold">
                      {{ lblInvoiceID }}
                    </td>
                    <td align="center">Due on receipt</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div v-if="showIsPaid" class="paidgraphc">
            <img height="106" :src="paidimageurl" width="255" />
          </div>

          <div class="row pt-2 inv-mobile">
            <div class="table-responsive">
              <table class="table table-transparent">
                <thead>
                  <tr>
                    <th>Placement</th>
                    <th class="text-left text-nowrap" style="width: 26%">Description</th>
                    <th class="text-center">Unit</th>
                    <th class="text-center non-mobile-nowrap">
                      <span class="d-none d-sm-block">Amount Due</span>
                      <span class="d-block d-sm-none">Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="invoiceItemDTO in displayInvoiceItemsDTOs"
                    v-bind:key="invoiceItemDTO.rowID"
                  >
                    <td class="text-left">
                      <p class="text mb-1">
                        {{ invoiceItemDTO.itemName }}
                      </p>
                      <div  v-if="invoiceItemDTO.itemName == 'Directory Listing Program'">
                        <div class="">
                        </div>
                        <div class="text-muted small indent">
                          {{ invoiceItemDTO.sitePlacement }}
                        </div>
                        <div class="text-muted small text-nowrap indent">
                          Intent Data Access
                        </div>
                        <div class="text-muted small text-nowrap indent">
                          MediaBrains Advertiser Center
                        </div>
                        <div class="text-muted small text-nowrap indent">
                          Professional Listing Setup
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      {{ invoiceItemDTO.description }}
                    </td>
                    <td class="text-center text-nowrap">
                      {{ invoiceItemDTO.unitText }}
                    </td>
                    <td class="text-end">
                      <span class="">
                        {{ invoiceItemDTO.renewAmount }}
                      </span>

                      <div class="ms-2 mt-1 text-right text-muted small italic text-end" v-if="invoiceItemDTO.itemName == 'Directory Listing Program'">
                        <div class="">
                        </div>
                        <div class="">
                          Included
                        </div>
                        <div class="">
                          Included
                        </div>
                        <div class="">
                          Included
                        </div>
                        <div class="">
                          Included
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr :class="[showinstallmenttotal ? '' : 'bottomborder']">
                    <td colspan="5" class="font-weight-bold text-end">
                      <span style="padding-right: 5px">{{ this.totalamountlabel }}</span>
                      <span style="padding-right: 2px">{{
                        this.money(this.totalamount)
                      }}</span>
                    </td>
                  </tr>
                  <tr class="bottomborder installmenttotalrow" v-if="showinstallmenttotal">
                    <td colspan="5" class="font-weight-bold text-end">
                      <span style="padding-right: 5px">{{ this.installmentamountlabel }}</span>
                      <span style="padding-right: 2px">{{
                        this.money(this.salesamount)
                      }}</span>
                    </td>
                  </tr>
                  <tr class="bottomborder" v-if="this.specialterms">
                    <td colspan="5" class="font-weight-bold text-start">
                      <div class="alert alert-info" role="alert">
                        <div class="d-flex justify-content-start">
                          <div>
                            <img src="@/assets/info.svg" />
                          </div>
                          <div>&nbsp;{{ this.specialterms }}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row pt-2" v-if="isPendingPaid && !showIsPaid">
            <div class="col-12">
              <div class="alert alert-info">
                <strong>We're currently processing your payment.</strong> If you
                have any questions please contact us at
                <a href="#">+1 (866) 299-2999</a>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="row pt-2" v-if="!showIsPaid">
              <br />
              <table cellSpacing="0" cellPadding="0" width="100%">
                <tr v-if="!ccOnly">
                  <td><p class="h3">Send payment to:</p></td>
                </tr>
                <tr v-if="!ccOnly">
                  <td>
                    MediaBrains-WF<br />
                    PO Box 931101<br />
                    Atlanta, GA 31193-1101<br />
                  </td>
                </tr>
                <tr v-if="!ccOnly">
                  <td><br /></td>
                </tr>

                <tr>
                  <td class="pb-2">
                    <strong>{{ this.paymenthint }}</strong>
                  </td>
                </tr>
              </table>
            </div>

            <div class="row p-0 mt-3" v-if="!showIsPaid">
              <div class="col-12 inv-mobile">
                <div class="d-block pb-2">
                  <button @click="paybycc()"
                    :class="['btn', 'btn-block', 'btn-primary', valid ? '' : 'disabled']"
                    >
                    <span class="paylabels"
                      >Pay By Credit Card Using Our Secured Site</span
                    >
                  </button>
                </div>
                <div class="d-block pt-3" v-if="showPayByCheck">
                  <button @click="paybyck()"
                    :class="['btn', 'btn-block', 'btn-primary', valid ? '' : 'disabled']"
                    >
                    <span class="paylabels">Pay By Sending in a Check</span>
                  </button>
                </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div
              class="text-center mt-5 conditions"
              v-if="showB2BLeadListTermsAndConditions"
            >
              <a :href="termsandconditions" target="_blank" class="termslabels">
                Terms and Conditions
              </a>
            </div>
            <div class="text-center mt-5 conditions" else>
              <a
                :href="advtermsandconditions"
                target="_blank"
                class="termslabels"
                >Advertiser Terms and Conditions</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center pt-2" v-else>
      <span
        class="spinner-border spinner-border-sm me-1"
        role="status"
      ></span> Loading
    </div>
  </div>
</template>

<style scoped>
.conditions {
  text-decoration: underline;
}
.paidgraphc {
  z-index: 999;
  left: 36%;
  position: absolute;
  top: 154px;
}
.container {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 15px 5px;
}
.bottomborder {
  border-bottom-color: #ffffff;
}
.row {
  padding-left: 5px;
}

.installmenttotalrow {
  background-color: #F1F1F5;
}

.non-mobile-nowrap {
  white-space: nowrap !important;
}

.indent {
  padding-left:20px !important;
}

.italic {
  font-style: italic;
}

@media (max-width: 420px) {
  .inv-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .text-nowrap {
    white-space: normal !important;
  }

  .text-center {
    text-align: left !important;
  }

  .table > :not(caption) > * > *,
  .markdown > table > :not(caption) > * > * {
    padding-left: 2px !important;
    padding-right: 2 !important;
  }

  .row {
    padding-left: 0px !important;
  }
}
</style>

<script>
import BillingService from "../../services/BillingService";
import TrackingService from "../../services/TrackingService";
import SalesMismatch from "../../components/Account/Billing/SalesAmountMismatch.vue";

export default {
  name: "InvoiceDetail",
  components: {
    SalesMismatch
  },
  data: () => {
    return {
      errormessage: "",
      invoiceDTO: [],
      ioDTO: [],
      invoiceItemsDTOs: [],
      siteLogoDTOs: [],
      existingInvoice: [],
      existingInvoicesItems: [],
      existingSiteLogos: [],
      lblAdvertiserInfo: "",
      lblInvoiceDate: "",
      lblInvoiceID: "",
      paidimageurl: "",
      paymenthint: "",
      paymenttype: "",
      ioid: "",
      iid: "",
      companyId: null,
      contactId: null,
      repId: null,
      termsandconditions: process.env.VUE_APP_MEDIABRAINS_URL + "tc",
      advtermsandconditions: process.env.VUE_APP_MEDIABRAINS_URL + "atc",
      totalamount: 0,
      companyName:"",
      installmentamountlabel: "",
      totalamountlabel: "",
      salesamount: 0,
      paymentamount: 0,
      installmentstatus: "",
      installmentduration: 0,
      invoiceid: "",
      insertionorderid: "",
      securitycheck: "",
      specialterms: "",
      b2bleadlisttermsandconditions: false,
      issalesamountmismatch: false,
      ccOnly: false,
      paybycheck: false,
      isPendingPaid: false,
      ispaid: false,
      rows: 0,
      loaded: false,
    };
  },
  methods: {
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },

    money(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(amount);
    },

    paybycc() {
      // goto pay by credit card page
      this.$router.push("/billing/paybycc?ioid=" + this.ioid);
    },

    paybyck() {
      // goto pay by credit card page
      this.$router.push(
        "/billing/paybycheck?invoiceid=" + this.invoiceid + "&iid=" + this.iid
      );
    },

    hasFeaturedPlacement(itemname){
      return itemname.indexOf("Featured Placement:") !== -1 || itemname.indexOf("Intent Data Access") !== -1 || itemname.indexOf("MediaBrains Advertiser Center") !== -1 || itemname.indexOf("Professional Listing Setup") !== -1
    },

    async LoadPendingPaid() {

      if(!this.iid && this.ioid)
      { 
        // don't bother checking as no iid found
        this.isPendingPaid = false;
      } 
      else
      {
        let invoiceFilter = {
          InvoiceID: `${this.invoiceid}`,
        };  

        // check if invoice paid
        let ispaid = await BillingService.isPaidInvoice(invoiceFilter);
        this.isPendingPaid = ispaid;
      }
    },

    async LoadInvoice() {
      
      let invoiceDTO;

      if(this.invoiceid)
      {
        let invoiceFilter = {
          InvoiceID: this.invoiceid
        };

        invoiceDTO = await BillingService.loadInvoice(invoiceFilter);
      }
      else
      {

        let insertionOrderFilter = {
          InsertionOrderID: this.insertionorderid,
        };

        invoiceDTO = await BillingService.loadInsertionOrder(insertionOrderFilter);

        if(invoiceDTO.invoiceID > 0)
        {
          this.invoiceid = invoiceDTO.invoiceID.toString();
        }

      }

      // get total amount of invoice
      this.paymenttype = invoiceDTO.paymentType;
      this.salesamount = invoiceDTO.salesAmount;
      this.totalamount = invoiceDTO.renewAmount;
      this.issalesamountmismatch = invoiceDTO.salesAmountMismatch;
      this.companyName = invoiceDTO.companyName;
      this.ispaid = invoiceDTO.paid;
      this.ccOnly = invoiceDTO.isCreditCardOnly;

      if(invoiceDTO.invoiceID > 0)
      {
        this.iid = invoiceDTO.invoiceID + invoiceDTO.invoiceGuid.toString().substring(0,3);
      }
      
      this.ioid = invoiceDTO.insertionOrderID + invoiceDTO.insertionOrderGuid.toString().substring(0,3);
      this.insertionorderid =  invoiceDTO.insertionOrderID.toString();
      this.companyId = invoiceDTO.companyId;
      this.contactId = invoiceDTO.contactID;
      this.repId = invoiceDTO.repID;
      this.specialterms = invoiceDTO.specialTerms;

      this.installmentstatus = invoiceDTO.installmentStatus;
      this.installmentduration = invoiceDTO.installmentDuration;

      this.totalamountlabel = "Total Amount Due";

      switch (this.installmentduration) {
        case 12:
          this.installmentamountlabel = "Annual Payment Due";
          this.totalamountlabel = "Total Cost";
          break;
        case 6:
          this.installmentamountlabel = "Semiannual Payment Due";
          this.totalamountlabel = "Total Cost";
          break;
        case 3:
          this.installmentamountlabel = "Quarterly Payment Due";
          this.totalamountlabel = "Total Cost";
          break;
        case 1:
          this.installmentamountlabel = "Monthly Payment Due";
          this.totalamountlabel = "Total Cost";
          break;
        default:
          break;
      }

      // set the paymenttype
      if (this.ccOnly) {
        this.paymenthint =
          "If you would like to pay by Credit Card, click on the link below";
        this.paybycheck = false;
      } else {
        this.paymenthint =
          "To confirm your order please click on a payment method below";
        this.paybycheck = true;
      }

      // invoice date is the current date for unpaid invoices or confirmdate for confirmed
      this.lblInvoiceDate = this.getFormattedDate(invoiceDTO.invoiceCreateDate);
      this.lblInvoiceID = "M" + invoiceDTO.invoiceID;

      //build address information
      let strCompleteAddress = invoiceDTO.billingAddress;

      if (invoiceDTO.billingAddress2 != "") {
        strCompleteAddress =
          strCompleteAddress + "<br> " + invoiceDTO.billingAddress2;
      }
      if (invoiceDTO.billingCity != "") {
        strCompleteAddress =
          strCompleteAddress + "<br>" + invoiceDTO.billingCity;
      }
      if (invoiceDTO.billingState != "") {
        if (invoiceDTO.billingCity != "")
          strCompleteAddress =
            strCompleteAddress + ", " + invoiceDTO.billingState;
        else
          strCompleteAddress =
            strCompleteAddress + "<br>" + invoiceDTO.billingState;
      }
      if (invoiceDTO.billingPostalCode != "") {
        strCompleteAddress =
          strCompleteAddress + " " + invoiceDTO.billingPostalCode;
      }
      if (invoiceDTO.billingCountry != "") {
        strCompleteAddress =
          strCompleteAddress + "<br>" + invoiceDTO.billingCountry;
      }

      //build billing attn name
      let strBillingAttn = invoiceDTO.billingAttn;

      //build company information
      let strCompleteAdvertiserInfo = invoiceDTO.companyName;

      if (strBillingAttn != "")
        strCompleteAdvertiserInfo += "<br>Attn: " + strBillingAttn;
      if (strCompleteAddress != "")
        strCompleteAdvertiserInfo += "<br>" + strCompleteAddress;

      this.lblAdvertiserInfo = strCompleteAdvertiserInfo;

      // save invoiceDTO
      this.existingInvoice = invoiceDTO;
    },

    async LoadInvoiceItems() {

      let invoiceItemsDTOs;

      if(this.iid)
      {
        let invoiceFilter = {
          InvoiceID: this.invoiceid,
        };

        invoiceItemsDTOs = await BillingService.loadInvoiceItems(
          invoiceFilter
        );
      }
      else{
        let insertionOrderFilter = {
          InsertionOrderID: this.insertionorderid,
        };

        invoiceItemsDTOs = await BillingService.LoadInsertionOrderItems(
          insertionOrderFilter
        );
      }
      
      // interate through press releases
      invoiceItemsDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // format money
        n.renewAmount = this.money(n.renewAmount);

        // build rowid
        n.rowID = this.rows;
      });

      // set BTBLeadListTermsAndConditions flag
      this.b2bleadlisttermsandconditions =
        invoiceItemsDTOs[0].b2BLeadListTermsAndConditions;

      // save invoiceItemsDTOs
      this.existingInvoicesItems = invoiceItemsDTOs;
    },

    async LoadSiteLogos() {
      let invoiceFilter = {
        InvoiceID: this.invoiceid,  
        InsertionOrderID: this.insertionorderid,
      };
      
      let siteLogoDTOs = await BillingService.loadSiteLogos(invoiceFilter);

      // interate through press releases
      siteLogoDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // format site image
        n.siteImageName = process.env.VUE_APP_SITE_LOGO_URL + n.siteImageName;

        // build rowid
        n.rowID = this.rows;
      });

      // save siteLogoDTOs
      this.existingSiteLogos = siteLogoDTOs;
    },

    async ProcessUrl() {
      // insertionorder id
      this.ioid = this.$route.query.ioid;

      // invoiceid
      this.iid = this.$route.query.iid;

      // payment type
      let pt = this.$route.query.pt;

      // initialize values
      let blnIsValidInvoice = true;

      // set paid image url
      this.paidimageurl = process.env.VUE_APP_CLIENT_IMAGE_URL + "paid.gif";

      // check for missing parameters
      if (!this.iid && !this.ioid) {
        this.errormessage = "Invalid Parameter Value: need iid or ioid";
        return;
      }

      // do not have iid and is old style pay by check
      if (!this.iid && pt && pt.toLowerCase() == "ck") {
        // set invoiceFilter to check ioid
        let invoiceFilter = {
          InsertionOrderID: this.ioid,
          CreateInvoice: true
        };

        this.iid = await BillingService.getIIDByIOID(invoiceFilter);

      }

      // payment that has insertionorderid and three character security but no invoiceid with three character security 
      if(!this.iid && this.ioid)
      {
        // set invoiceFilter to check ioid
        let invoiceFilter = {
          InsertionOrderID: this.ioid,
          CreateInvoice: false
        };

        // try and set iid
        this.iid = await BillingService.getIIDByIOID(invoiceFilter);

        // get InsertionOrderID
        this.insertionorderid = this.ioid
        .toString()
        .substring(0, this.ioid.toString().length - 3);

      }

      if(this.iid)
      {
        // get InvoiceID
        this.invoiceid = this.iid
          .toString()
          .substring(0, this.iid.toString().length - 3);

        // set securitycheck
        this.securitycheck = this.iid.toString().replace(this.invoiceid, "");

      }

      await this.LoadInvoice();

      // redirect to processing page if valid
      if (blnIsValidInvoice) {
        if (!pt) {
          // load the invoice if there is no payment type
          await this.LoadInvoiceItems();
          await this.LoadSiteLogos();
          await this.LoadPendingPaid();  // will display PAID logo if invoice paid        
          this.loaded = true;

          this.$nextTick(() => {
            // check for sales amount mismatch (i.e. invoice sales amount does not equal insertoinorder salesamount)
            if (this.issalesamountmismatch) {
              console.log("show modal");
              this.$bvModal.show("sales-amount-mismatch-modal"); 
            }
          });

        } else if (pt.toLowerCase() == "cc") {

          let url;

          if(this.invoiceid)
          {
            url = "/billing/paybycc?invoiceid=" + this.invoiceid;
          }
          else if(this.iid)
          {
            url = "/billing/paybycc?iid=" + this.iid
          }
          
          if(this.url && this.ioid)
          {
            url += "&ioid=" + this.ioid;
          }
          else{
            url = "/billing/paybycc?ioid=" + this.ioid;
          }

          this.$router.push(
            url
          );
        } else if(pt.toLowerCase() == "ck"){
          // goto pay by check page
          this.$router.push(
            "/billing/paybycheck?invoiceid=" +
              this.invoiceid +
              "&iid=" +
              this.ioid
          );
        }
      } 
      else {
        //invalid invoice
        this.errormessage =
          "The application was not able to validate the invoice.";
        return;
      }
    },
  },

  computed: {
    displayInvoiceItemsDTOs() {
      return this.existingInvoicesItems;
    },

    displaySiteLogoDTOs() {
      return this.existingSiteLogos;
    },

    showB2BLeadListTermsAndConditions() {
      return this.b2bleadlisttermsandconditions;
    },

    showIsPaid() {
      return this.ispaid;
    },

    showPayByCheck() {
      return this.paybycheck;
    },

    showinstallmenttotal(){
      return (this.installmentstatus != "" && this.installmentstatus == "n") ? false : true;
    },
    valid() {
      return (
        !this.issalesamountmismatch
      );
    },
    displaySalesMismatchWarning() {
      return this.issalesamountmismatch;
    },
  },

  async mounted() {
    await this.ProcessUrl();
    
    TrackingService.track("viewed invoice", {
      companyId: this.companyId,
      contactId: this.contactId,
      repId: this.repId,
    });
  },
};
</script>
